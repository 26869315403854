@import '../../constants';

.header {
  font-family: 'Kanit';
  box-shadow: 0 2px 1px 0 rgba(0, 0, 0, .05);
  background: white;

  .title {
    padding: 1px 20px 1px 5px;
    font-size: 40px;
    color: black;
    display: inline-block;
  }

  .nav {

    .nav-element {
        padding: 10px;
        font-size: 20px;
        text-decoration: none;
        color: black;
        display: inline-block;

        &:hover {
            color: #ff6666;
        }
    }
  }
}