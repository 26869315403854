@import '../../constants';

$imageSize: 64px;

.socials {
  font-family: 'Kanit';

  max-width: 400px;
  margin: auto;
  display:flex;

  // Apply this rule to all children (*) of .social-row
  & > * {
    padding: 10px;
  }

  & img, iframe {
    width: $imageSize;
    height: $imageSize;
  }
}