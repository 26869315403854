@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{text-align:center}

.header{font-family:'Kanit';box-shadow:0 2px 1px 0 rgba(0,0,0,0.05);background:white}.header .title{padding:1px 20px 1px 5px;font-size:40px;color:black;display:inline-block}.header .nav .nav-element{padding:10px;font-size:20px;text-decoration:none;color:black;display:inline-block}.header .nav .nav-element:hover{color:#ff6666}

.about-page{width:60%;margin-left:auto;margin-right:auto}


.socials{font-family:'Kanit';max-width:400px;margin:auto;display:flex}.socials>*{padding:10px}.socials img,.socials iframe{width:64px;height:64px}


.logo img{display:block;margin-left:auto;margin-right:auto;width:25%}


.merch-grid{display:flex;flex-flow:row wrap;justify-content:space-around}


